import { AutoAwesome } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { useWindowDimensions } from 'components/hooks/window'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setYourStoryText } from 'store/account/yourStory'
import TextLength from 'toolComponents/text-length'
import { Input, Microbutton } from 'ui'

const StoryGenerator = ({ storyText, wordLength, setWordLength }) => {
  const { t } = useTranslate()
  const { height } = useWindowDimensions()
  const dispatch = useDispatch()

  const [isEnhance, setIsEnhance] = useState(false)

  const handleInputChange = (text) => {
    dispatch(setYourStoryText(text))
  }

  useEffect(() => {
    const numWords = storyText?.split(/\s+/)?.length
    const wordLength = storyText?.length ? numWords : 0
    setWordLength(wordLength)
  }, [storyText])

  return (
    <div className='items-start space-y-[6px]' data-tooltip-id='yourStory-yourStoryText'>
      <div>
        <p className='text-brand-body flex-shrink-0 flex-grow-0 break-words text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-your-story-text'>Your story text</T>
        </p>
      </div>
      <div id='textbox' className='relative w-full'>
        <Input
          placeholder={t('eleo-your-story-placeholder', 'Write your story')}
          className='placeholder-font-regular min-h-[300px] w-full rounded-[8px] pb-8 text-[13px] placeholder-[#36363680]'
          onChange={handleInputChange}
          value={storyText}
          isTextArea
          resize='none'
        />

        <Microbutton
          iconPlacement='left'
          variant='transparent-light'
          icon={<AutoAwesome fontSize='18' />}
          text={t('eleo-enhance-for-chatbot', 'Enhance for Chatbot')}
          className={classNames('!absolute bottom-10 right-3 !m-0 h-[28px]')}
          onClick={() => setIsEnhance(true)}
        />

        {isEnhance && (
          <div className='absolute left-2 top-0 z-50 -translate-y-full'>
            <ContextMenu
              noHeader
              selectedText={storyText}
              tools={['rephrase']}
              forceNotSynonyms
              regenerateUrl='/api/ai/enhance_prompt'
              type='chatbot-instructions'
              handleChangeSelectedText={(newText) => {
                handleInputChange(newText)
                setIsEnhance(false)
              }}
              onClose={() => setIsEnhance(false)}
            />
          </div>
        )}

        <TextLength
          numWords={wordLength}
          characters={storyText?.length}
          limit={4000}
          className='bottom-2'
        />
      </div>
    </div>
  )
}

export default StoryGenerator
