import { T, useTranslate } from '@tolgee/react'
import { AuthContext } from 'app/auth'
import axios from 'axios'
import { addContextToFormData } from 'components/document-context/helper'
import { useDocumentContext } from 'components/hooks/context'
import { useStream } from 'components/hooks/stream'
import { errorToast, languages, transformDashToSpace } from 'helpers'
import { useCallback, useContext, useEffect, useState } from 'react'
import ChatBotUi from 'toolComponents/chat-bot/chatBotUi'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'
import { v4 as uuidv4 } from 'uuid'

export function ChatbotPreviewWrapper({
  chatBotSettings,
  chatBotInputValues,
  isDraft,
  isReady,
  chatbotContext,
}) {
  const authContext = useContext(AuthContext)
  const { t } = useTranslate()

  const { state: completionState, abortController, fetch: fetchCompletion } = useStream(false)

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [audioStream, setAudioStream] = useState()
  const [language, setLanguage] = useState('auto')
  const [isSearchWeb, setSearchWeb] = useState(false)
  const [isAutoAudio, setIsAutoAudio] = useState(false)
  const [ttsIndex, setTtsIndex] = useState()
  const [chatId, setChatId] = useState()
  const [isGenerating, setIsGenerating] = useState(false)

  const documentContext = useDocumentContext('chatbot_preview')

  useEffect(() => {
    documentContext.clearContext()
  }, [])

  useEffect(() => {
    if (!completionState.message) return
    setMessages((prevChatLog) => {
      const newChatLog = [...prevChatLog]
      newChatLog[newChatLog.length - 1] = {
        role: 'assistant',
        content: completionState.message,
      }
      return newChatLog
    })
  }, [completionState.message])

  useEffect(() => {
    const cmd = completionState.command
    if (cmd?.chatId) setChatId(cmd.chatId)
    if (cmd?.toolResult)
      setMessages((prevChatLog) => {
        const newChatLog = [...prevChatLog]
        const lastMessage = { ...newChatLog[newChatLog.length - 1] }
        lastMessage.image = cmd.toolResult
        if (
          lastMessage.content === 'STATE_LOADING' ||
          lastMessage.content === 'STATE_LOADING_CONTEXT'
        )
          lastMessage.content = ''
        newChatLog[newChatLog.length - 1] = lastMessage
        return newChatLog
      })
  }, [completionState.command])

  useEffect(() => {
    setSearchWeb(chatBotInputValues.isStartWithWebSearch)
  }, [chatBotInputValues.isStartWithWebSearch])

  // Preview intro
  useEffect(() => {
    if (chatBotInputValues?.intro)
      setMessages([{ role: 'assistant', content: chatBotInputValues?.intro }])
    else setMessages([])
  }, [chatBotInputValues?.intro, setMessages])

  function mockSendMessage() {
    if (!message.length) return

    setMessages((prev) => [
      ...prev,
      { role: 'user', content: message },
      { role: 'assistant', content: 'This is an example response from your chatbot' },
    ])
    setMessage('')

    if (isAutoAudio) handleTTS('This is an example response from your chatbot', messages.length + 1)
  }

  const handleChat = useCallback(
    async (e, question, newChat = false) => {
      e?.preventDefault()

      if (!isReady)
        return errorToast(
          t('eleo-error-preview-not-ready', 'Save as draft or publish to enable previewing')
        )

      if (!message?.length && !question?.length) {
        return
      }

      let newChatLog
      if (question && newChat) newChatLog = [{ role: 'user', content: question }]
      else {
        const contextFileInfo = documentContext?.docContext?.map((item) => {
          return {
            name: item.label,
            fileType: item.type,
          }
        })
        const newMessage = {
          role: 'user',
          content: question ?? message,
          attachedFileInfo: contextFileInfo,
          id: uuidv4(),
        }
        newChatLog = [...messages, { ...newMessage, context: documentContext?.docContext }]
      }

      setMessage('')
      setMessages(newChatLog)

      let formData = new FormData()
      formData.append('messages', JSON.stringify(newChatLog))
      formData.append(
        'language',
        chatBotInputValues.allowLanguageChange ? language : chatBotInputValues.language
      )
      if (chatBotSettings.modelId) formData.append('model', chatBotInputValues.modelId)
      formData.append('name', transformDashToSpace(chatBotInputValues.name))
      if (chatBotSettings.monthlyLimit)
        formData.append('monthlyLimit', chatBotInputValues.monthlyLimit)
      formData.append('responseLength', chatBotInputValues.responseLength)
      if (chatBotSettings.tone) formData.append('tone', chatBotInputValues.tone)
      formData.append('account_id', authContext?.user.account_id)
      formData.append('id', chatBotInputValues.selectedChatBot)
      formData.append('stories', JSON.stringify(chatBotInputValues.stories ?? []))
      formData.append(
        'context',
        JSON.stringify(
          chatbotContext.docContext.map((item) => ({ id: item.id, type: 'chatbot_doc' }))
        )
      )
      formData.append('prompt', question ?? message)
      formData.append('isDraft', isDraft ? 1 : 0)
      if (isSearchWeb) formData.append('isSearchWeb', isSearchWeb)

      if (chatId) formData.append('chatId', chatId)
      formData.append('kwargs', JSON.stringify({ stream: true }))
      formData = await addContextToFormData(formData, [...documentContext.docContext])

      try {
        setIsGenerating(true)

        const usesContext = documentContext.docContext?.length || isSearchWeb
        setMessages((prevChatLog) => [
          ...prevChatLog,
          { role: 'assistant', content: usesContext ? 'STATE_LOADING_CONTEXT' : 'STATE_LOADING' },
        ])
        const completion = await fetchCompletion('/api/chat-bot/conversation', formData)

        // If completion hasn't started and the user aborted, remove the placeholder
        setTimeout(() => {
          if (!completion?.length)
            return setMessages((prev) =>
              prev.filter((message, index) => index !== prev.length - 1 || message.image)
            )
        }, 500)

        if (isAutoAudio) handleTTS(completion, messages.length + 1)
      } catch (err) {
        const limitErrorMessage = {
          role: 'assistant',
          content: t(
            'eleo-chat-limit-error-primary',
            "Oops! It looks like I've run out of words for the moment... Try asking me later."
          ),
        }
        const errorMessage = {
          role: 'assistant',
          content: t(
            'eleo-chat-error-primary',
            "I'm not feeling quite well... Try asking me later."
          ),
        }

        if (err.name === 'LimitError') {
          setMessages((prevChatLog) => {
            const newChatLog = [...prevChatLog]
            newChatLog[newChatLog.length - 1] = limitErrorMessage
            return newChatLog
          })
        } else {
          setMessages((prevChatLog) => {
            const newChatLog = [...prevChatLog]
            newChatLog[newChatLog.length - 1] = errorMessage
            return newChatLog
          })
        }
      } finally {
        setIsGenerating(false)
      }
    },
    [chatBotSettings, chatId, fetchCompletion, language, message, messages, t]
  )

  const handleTTS = async (text, index) => {
    setAudioStream()
    if (index === ttsIndex) {
      setTtsIndex()
      return
    }

    setTtsIndex(index)
    const data = {
      input: text,
      language: languages.find((lang) => lang.value === language)?.code,
    }
    if (chatBotSettings.voice) data.voiceId = chatBotSettings.voice

    const res = await fetch(axios.defaults.baseURL + '/api/chat-bot/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  return (
    <>
      <div className='-ml-[5px] h-full'>
        <ChatBotUi
          chatBotSettings={{ ...chatBotSettings, ...chatBotInputValues }}
          messages={messages}
          setMessages={setMessages}
          message={message}
          setMessage={setMessage}
          handleChat={handleChat}
          audioStream={audioStream}
          setAudioStream={setAudioStream}
          documentContext={documentContext}
          language={language}
          setLanguage={setLanguage}
          isSearchWeb={isSearchWeb}
          setSearchWeb={setSearchWeb}
          isAutoAudio={isAutoAudio}
          setIsAutoAudio={setIsAutoAudio}
          ttsIndex={ttsIndex}
          setTtsIndex={setTtsIndex}
          setChatId={setChatId}
          chatId={chatId}
          handleTTS={handleTTS}
        />
      </div>
      <AudioPlayer streamSource={audioStream} setSource={setAudioStream} callback={setTtsIndex} />
    </>
  )
}

export default ChatbotPreviewWrapper
