import { Close, History } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ContextModal } from 'components/document-context/ContextModal'
import { addContextToFormData } from 'components/document-context/helper'
import { useStream } from 'components/hooks/stream'
import { useWindowDimensions } from 'components/hooks/window'
import { useDetectLandscapeMode, ViewContext } from 'components/lib'
import { errorToast } from 'helpers'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { chatForm, chatInitialState, setChatForm } from 'store/toolInputHistoriesHolder/chat'
import AudioPlayer from '../generic/audio/AudioPlayer'
import { ChatForm } from './ChatForm'
import ChatMessage from './ChatMessage/ChatMessage'
import { v4 as uuidv4 } from 'uuid'

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INPUT':
      return { ...state, inputValue: action.payload }
    case 'APPEND_INPUT':
      return { ...state, inputValue: state.inputValue + ' ' + action.payload }
    case 'SET_SEARCH_WEB':
      return { ...state, isSearchWeb: action.payload }
    case 'SET_AUTO_AUDIO':
      return { ...state, isAutoAudio: action.payload }
    case 'TOGGLE_SHOW_SETTINGS':
      return { ...state, isSettingsOpen: !state.isSettingsOpen }
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload }
    case 'SET_MODEL':
      return { ...state, model: { ...action.payload, description: undefined } }

    case 'RESET':
      return chatInitialState.form
    default:
      return state
  }
}
export default function ChatPanel({
  chatLog,
  setChatLog,
  chatId,
  setChatId,
  showHistory,
  activeHistory,
  setActiveHistory,
  setShowHistory,
  getChatHistory,
  documentContext,
  messageContext,
  setShowWebCam,
}) {
  const dispatchAction = useDispatch()
  const chatFormValues = useSelector(chatForm)
  const { width } = useWindowDimensions()

  // const [googleResults, setgoogleResults] = useState(10)
  // const [depth, setdepth] = useState(3)
  // const [pagesPerDepth, setpagesPerDepth] = useState(7)
  // const [ragResults, setragResults] = useState(20)
  // const [chunkSize, setchunkSize] = useState(5000)

  const [state, dispatch] = useReducer(reducer, chatFormValues)
  useEffect(() => {
    dispatchAction(setChatForm(state))
  }, [state, dispatchAction])

  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const context = useContext(ViewContext)
  const [isLoading, setIsLoading] = useState(false)
  const scrollHandle = useRef()
  const messageBox = useRef()
  const { t } = useTranslate()
  const location = useLocation()

  const [audioStream, setAudioStream] = useState()
  const [ttsIndex, setTtsIndex] = useState()
  const [isAutoScrollDisabled, setIsAutoScrollDisabled] = useState(false)

  const { isLandscape } = useDetectLandscapeMode()
  const [imagePreviewData, setImagePreviewData] = useState({
    target: '',
    isMobile: false,
    isVisible: false,
  })

  // Load form data
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const message = queryParams.get('message')
    if (message) dispatch({ type: 'SET_INPUT', payload: message })
  }, [location])

  useEffect(() => {
    !isAutoScrollDisabled && scrollHandle?.current?.scrollIntoView()
  }, [chatLog, scrollHandle, isAutoScrollDisabled])

  useEffect(() => {
    if (!completionState.message) return

    setChatLog((prevChatLog) => {
      const newChatLog = [...prevChatLog]
      newChatLog[newChatLog.length - 1] = {
        role: 'assistant',
        content: completionState.message,
      }
      return newChatLog
    })
  }, [completionState.message])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) {
      console.log('Words used: ', cmd.wordsUsed)
      context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    }
    if (cmd.activeHistory) setActiveHistory(cmd.activeHistory)
    if (cmd.chatId) setChatId(cmd.chatId)
    if (cmd.toolResult)
      setChatLog((prevChatLog) => {
        const newChatLog = [...prevChatLog]
        const lastMessage = { ...newChatLog[newChatLog.length - 1] }
        lastMessage.image = cmd.toolResult
        if (
          lastMessage.content === 'STATE_LOADING' ||
          lastMessage.content === 'STATE_LOADING_CONTEXT'
        )
          lastMessage.content = ''
        newChatLog[newChatLog.length - 1] = lastMessage
        return newChatLog
      })
    if (cmd.error)
      errorToast(
        t(
          'eleo-error-switch-model',
          'The model you are using is unavailable. Try later or choose a different model in the settings.'
        )
      )
  }, [completionState.command])

  // Abort the request when clearing chat log
  useEffect(() => {
    if (abortController && !chatId) {
      abortController.abort()
    }

    setAudioStream(null)
  }, [chatId])

  const handleSubmit = (event, message) => {
    if (event) event.preventDefault()

    if (isLoading || (state.inputValue.trim() === '' && !message?.trim()?.length)) return

    const context = [...documentContext.docContext, ...messageContext.docContext]
    const contextFileInfo = context.map((item) => {
      return {
        name: item.label,
        fileType: item.type,
      }
    })
    const newMessage = {
      role: 'user',
      content: message ?? state.inputValue,
      attachedFileInfo: contextFileInfo,
      id: uuidv4(),
    }
    const newChatLog = [...chatLog, { ...newMessage, context }]
    setChatLog(newChatLog)

    const newActiveHistory = [...activeHistory, newMessage]

    setIsAutoScrollDisabled(false)
    sendMessage(state.inputValue, newActiveHistory, {}, [], newChatLog)
    dispatch({ type: 'SET_INPUT', payload: '' })
    messageContext.clearContext()
  }

  const sendMessage = async (
    message,
    messages,
    kwargs = {},
    chosenMessageContext = [],
    chatLog
  ) => {
    if (!messages.length) return
    setIsLoading(true)
    const url = '/api/ai/text'

    if (state.model?.supportsStreaming) kwargs = { ...kwargs, stream: true }

    let formData = new FormData()

    // Add images from past messages
    for (const [index, message] of messages.entries()) {
      if (index === messages.length - 1) break

      const messageFromMemory = chatLog.find((x) => x.id === message.id)
      if (!messageFromMemory?.context) continue

      const image = messageFromMemory.context.find((item) => item.type === 'image')
      if (image) {
        const res = await fetch(image.content)
        const blob = await res.blob()
        formData.append(message.id, blob)
      }
    }

    formData.append('messages', JSON.stringify(messages))
    formData.append('language', state.language?.value)
    formData.append('model', state.model?.value)
    if (state.isSearchWeb) formData.append('isSearchWeb', state.isSearchWeb)
    if (chatId) formData.append('chatId', chatId)
    formData.append('kwargs', JSON.stringify(kwargs))
    // formData.append(
    //   'serpParams',
    //   JSON.stringify({
    //     googleResults: Math.min(10, Number(googleResults)),
    //     depth: Math.min(3, Number(depth)),
    //     pagesPerDepth: Number(pagesPerDepth),
    //     ragResults: Number(ragResults),
    //     chunkSize: Number(chunkSize),
    //   })
    // )

    formData = await addContextToFormData(formData, [
      ...documentContext.docContext,
      ...messageContext.docContext,
      //append context of a message which was chosen during regeneration
      ...chosenMessageContext,
    ])

    const usesContext =
      documentContext.docContext?.length || messageContext.docContext?.length || state.isSearchWeb
    // Create new message for further editing
    setChatLog((prevChatLog) => [
      ...prevChatLog,
      { role: 'assistant', content: usesContext ? 'STATE_LOADING_CONTEXT' : 'STATE_LOADING' },
    ])

    try {
      const completion = await fetchCompletion(url, formData)

      // If completion hasn't started and the user aborted, remove the placeholder
      setTimeout(() => {
        if (!completion?.length)
          return setChatLog((prev) =>
            prev.filter((message, index) => index !== prev.length - 1 || message.image)
          )
      }, 500)

      // Update history data
      const pages = Math.max(1, Math.ceil((messages.length + 1) / 20))
      setTimeout(() => getChatHistory(pages), 500)

      // Generate and play TTS
      if (state.isAutoAudio) handleTTS(completion, messages.length)
    } catch (error) {
      if (error.name === 'LimitError') {
        setChatLog((prevChatLog) => {
          const newChatLog = [...prevChatLog]
          newChatLog[newChatLog.length - 1] = {
            role: 'assistant',
            content: `${t(
              'eleo-chat-error-secondary',
              'It seems you have run out of words. Try upgrading your plan or buying more words at'
            )} [Your account page](/account/plans)`,
          }
          return newChatLog
        })
      } else {
        console.log(error)
        setChatLog((prevChatLog) => {
          const newChatLog = [...prevChatLog]
          newChatLog[newChatLog.length - 1] = {
            role: 'assistant',
            content: t(
              'eleo-chat-error-primary',
              'Oops! I need a moment to collect information. Ask me again later, please.'
            ),
          }
          return newChatLog
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const regenerateResponse = (index) => {
    if (isLoading) return

    const chosenMessage = chatLog[index]
    const chosenMessageContext = chosenMessage?.context
    const newChatLog = [...chatLog, chosenMessage]
    setChatLog(newChatLog)
    const newActiveHistory = [...activeHistory, chosenMessage]
    sendMessage('', newActiveHistory, {}, chosenMessageContext, newChatLog)
  }

  const handleClearContext = () => {
    handleStopRequest()
    setChatId(null)
    setChatLog([])
    setActiveHistory([])
    messageContext.clearContext()
  }

  const handleTTS = async (text, index) => {
    setAudioStream()
    if (index === ttsIndex) {
      setTtsIndex()
      return
    }

    setTtsIndex(index)
    const data = {
      input: text,
    }

    const res = await fetch(axios.defaults.baseURL + '/api/utility/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  const handleSetUserSettings = async () => {
    //apply settings from localStorage
    const chatSettings = localStorage.getItem('settings')
    const localSettings = chatSettings ? JSON.parse(chatSettings) : null

    if (localSettings) {
      dispatch({ type: 'SET_LANGUAGE', payload: localSettings.language })
      dispatch({ type: 'SET_MODEL', payload: localSettings.model })
    }

    // sync from server
    try {
      const { data: response } = await axios.get(`/api/user/settings/info?toolName=chat`)
      const serverSettings = response.data

      if (serverSettings) {
        const syncedSettings = { ...localSettings, ...serverSettings }

        dispatch({ type: 'SET_LANGUAGE', payload: syncedSettings.language })
        dispatch({ type: 'SET_MODEL', payload: syncedSettings.model })
        // save synced settings to localStorage
        localStorage.setItem('settings', JSON.stringify(syncedSettings))
      }
    } catch (error) {
      console.error('Error fetching server settings:', error)
    }
  }

  useEffect(() => {
    handleSetUserSettings()
  }, [])

  return (
    <>
      <div className='relative h-full min-w-0 flex-1'>
        {!showHistory && (
          <div
            className='text-brand-secondary absolute left-2 top-2 z-20 flex cursor-pointer items-center gap-1 text-[13px] uppercase'
            onClick={() => setShowHistory((prev) => !prev)}
            data-tooltip-id={`chat-history`}
            id='chat-history'
          >
            <History />
            <T keyName='eleo-tool-chat-history-header'>History</T>
          </div>
        )}

        <div
          className={classNames(
            'h-full',
            showHistory ? 'md:-ml-[5px]' : 'md:-ml-4',
            !isLandscape && 'overflow-hidden'
          )}
          onWheel={() => !isAutoScrollDisabled && setIsAutoScrollDisabled(true)}
          onTouchMove={() => !isAutoScrollDisabled && setIsAutoScrollDisabled(true)} // Handle mobile scroll
        >
          <div
            className={classNames(
              'mx-auto flex h-full flex-1 flex-col items-center',
              isLandscape && 'overflow-y-scroll'
            )}
          >
            <div
              className={classNames(
                'mx-auto flex w-full flex-1 flex-grow flex-col items-center',
                !isLandscape && 'overflow-auto'
              )}
            >
              <div
                className='flex w-full max-w-[1100px] flex-grow flex-col space-y-4 px-2 pb-8 pt-4 sm:px-[70px]'
                ref={messageBox}
              >
                {chatLog.map((message, index) => (
                  <ChatMessage
                    message={message}
                    index={index}
                    key={index}
                    regenerateResponse={regenerateResponse}
                    avatar={context.avatar}
                    language={state.language?.value}
                    handleTTS={handleTTS}
                    width={width}
                    ttsIndex={ttsIndex}
                    setImagePreviewData={setImagePreviewData}
                  />
                ))}

                <div ref={scrollHandle} />
              </div>
            </div>

            <div
              className={classNames(
                'bg-brand-grey-bg bottom-0 z-10 -mx-8 flex w-full justify-center',
                !isLandscape && 'sticky'
              )}
            >
              <ChatForm
                isGenerating={isLoading}
                handleSubmit={handleSubmit}
                handleAbort={handleStopRequest}
                state={state}
                dispatch={dispatch}
                handleClearContext={handleClearContext}
                documentContext={documentContext}
                audioStream={audioStream}
                setAudioStream={setAudioStream}
                handleStopRequest={handleStopRequest}
                messageContext={messageContext}
                isHistoryVisible={showHistory}
                width={width}
                setTtsIndex={setTtsIndex}
                setShowWebCam={setShowWebCam}
              />
              {/* Temporary serp testing params */}
              {/* {state.isSearchWeb && (
                <div className=' bg-gradient-to-bl from-teal-400 to-blue-500 p-1 '>
                  <span>Google results (max 10)</span>
                  <Input
                    value={googleResults}
                    onChange={setgoogleResults}
                    type='number'
                    min={1}
                    max={10}
                  />
                  <span>Depth (1=brak pogłębiania, max 3)</span>
                  <Input value={depth} onChange={setdepth} type='number' min={1} max={3} />
                  <span>Pages per depth (max 10)</span>
                  <Input
                    value={pagesPerDepth}
                    onChange={setpagesPerDepth}
                    type='number'
                    min={1}
                    max={10}
                  />
                  <span>Rag page count (max 50)</span>
                  <Input
                    value={ragResults}
                    onChange={setragResults}
                    type='number'
                    min={1}
                    max={50}
                  />
                  <span>Rag chunk size (characters)</span>
                  <Input
                    value={chunkSize}
                    onChange={setchunkSize}
                    type='number'
                    min={1000}
                    max={10000}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <ContextModal documentContext={documentContext} />
      <AudioPlayer streamSource={audioStream} setSource={setAudioStream} callback={setTtsIndex} />
      {imagePreviewData?.isVisible && (
        <div
          className={classNames(
            'fixed inset-0 z-[999] flex items-center justify-center',
            imagePreviewData.isMobile ? 'bg-black' : 'bg-black/90'
          )}
          onClick={(e) =>
            e.target === e.currentTarget &&
            setImagePreviewData((prev) => ({ ...prev, isVisible: false }))
          }
        >
          <div className='relative lg:h-[70dvh]'>
            <img
              className={classNames(
                'lg:max-h-auto max-h-[60dvh] object-contain shadow-lg lg:h-[70dvh] lg:w-full lg:object-cover',
                imagePreviewData.isMobile ? 'w-full' : 'w-[90vw] rounded-lg'
              )}
              src={imagePreviewData.target}
              alt=''
            />
            {!imagePreviewData.isMobile && (
              <Close
                size={32}
                className='absolute -right-[2px] -top-8 text-white hover:cursor-pointer'
                onClick={() => setImagePreviewData((prev) => ({ ...prev, isVisible: false }))}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
