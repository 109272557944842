import { T } from '@tolgee/react'
import { useDispatch } from 'react-redux'
import { setClearFields } from 'store/account/yourStory'
import { Microbutton } from 'ui'

const StoryActions = ({ handleShowSaveTemplateModal, documentContext }) => {
  const dispatch = useDispatch()

  const handleResetFields = () => {
    dispatch(setClearFields())
    documentContext.clearContext()
  }

  return (
    <div className='flex gap-[10px]'>
      <Microbutton
        variant='transparent-light'
        type='main'
        className='h-[40px] uppercase'
        onClick={handleResetFields}
        data-tooltip-id='yourStory-yourStoryClear'
        text={<T keyName='eleo-reset-changes'>reset changes</T>}
      ></Microbutton>
      <Microbutton
        id='save'
        variant='solid'
        type='main'
        className='h-[40px] flex-1 uppercase'
        onClick={handleShowSaveTemplateModal}
        text={<T keyName='eleo-save'>save</T>}
      ></Microbutton>
    </div>
  )
}

export default StoryActions
