import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import { addContextToFormData } from 'components/document-context/helper'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useDocumentContext } from 'components/hooks/context'
import { ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { errorToast, successToast, transformSelectOptionValue } from 'helpers'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { YourStoryAPIService } from 'service/your-story-rest-api.service'
import {
  SelectChosenTemplate,
  SelectYourStoryText,
  setClearFields,
  setSelectedTemplate,
  setYourStoryText,
} from 'store/account/yourStory'
import ActionModal from 'toolComponents/writer/ActionModal'
import { useGetTemplateList } from 'toolComponents/writer/hooks/useGetTemplateList'
import { Button, ReactSelect } from 'ui'
import StoryActions from './StoryActions'
import { StoryDocs } from './StoryDocs'
import StoryGenerator from './StoryGenerator'

// Tour steps
const steps = [
  {
    target: '#selector',
    content: (
      <T keyName='eleo-tour-story-1'>
        Edit and update Your Story. Choose a previously created Your Story to update information.
      </T>
    ),
  },
  {
    target: '#textbox',
    content: (
      <T keyName='eleo-tour-story-2'>
        Write Your Story. Paste or type information about yourself or your company as text. You can
        use it when creating documents or launching a Chatbot.
      </T>
    ),
  },
  {
    target: '#save',
    content: (
      <T keyName='eleo-tour-story-3'>
        Save changes. You can create multiple different Your Story files.
      </T>
    ),
  },
]

const YourStory = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const context = useContext(ViewContext)
  const storyText = useSelector(SelectYourStoryText)
  const selectedStory = useSelector(SelectChosenTemplate)
  const [latestTemplateId, setLatestTemplateId] = useState('')
  const [wordLength, setWordLength] = useState(0)
  const [loading, setLoading] = useState({ name: '', status: false })
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: '',
  })
  const [templateName, setTemplateName] = useState(selectedStory.templateName)
  const [templateIdToDelete, setTemplateIdtoDelete] = useState('')
  const { templateList, reload, templatesRecordId, templateOptions, templateListLoading } =
    useGetTemplateList('your_story_templates', '/your-story/templates')

  const [documentsToDelete, setDocumentsToDelete] = useState([])
  const [documentsToUpload, setDocumentsToUpload] = useState([])
  const [websitesToDelete, setWebsitesToDelete] = useState([])
  const [websitesToUpload, setWebsitesToUpload] = useState([])

  const documentContext = useDocumentContext('yourStory')

  useEffect(() => {
    setDocumentsToUpload([])
    setDocumentsToDelete([])
    setWebsitesToDelete([])
    setWebsitesToUpload([])
  }, [selectedStory])

  const handleSelectTemplate = (selectedTemplateId) => {
    const template = templateList.find((template) => {
      return template.id === selectedTemplateId.value
    })

    if (template) {
      dispatch(
        setSelectedTemplate({
          id: template.id,
          templateName: template.templateName,
          storyText: template.storyText ?? '',
          documents: template.documents,
        })
      )

      documentContext.clearContext()
      template.documents.map((doc) =>
        documentContext.addDocument({
          label: doc.name,
          id: doc.document_id,
          type: doc.mimetype === 'url' ? 'website' : 'document',
          invalid: doc.mimetype === 'url' ? false : true,
          isChunk: doc.is_chunk,
        })
      )

      dispatch(setYourStoryText(template.storyText))
    }
  }

  const showSaveTemplateModal = () => {
    if (!storyText.length) {
      errorToast(
        <T keyName='eleo-your-story-validation-provide-story'>Please provide your story!</T>
      )
    } else if (wordLength > 4000) {
      errorToast(
        <T keyName='eleo-your-story-validation-word-length'>
          Your story should have a max length of 4000 words!
        </T>
      )
    } else {
      setModalIsVisible({ content: 'SAVE_STORY_TEMPLATE', visible: true })
    }
  }

  const handleSaveStoryTemplate = async () => {
    if (!templateName.length) {
      errorToast(
        t('eleo-save-template-error-please-point-template-name', 'Please point template name')
      )
      return
    }

    // if (templateName && templateName.length < 5) {
    //   errorToast(
    //     t('eleo-save-template-error-name-length', 'Template name should be more than 5 characters')
    //   )
    //   return
    // }

    let data = new FormData()
    if (templatesRecordId) data.append('templatesRecordId', templatesRecordId)
    if (selectedStory.id) data.append('templateId', selectedStory.id)
    data.append('your_story_templates[templateName]', templateName)
    data.append('your_story_templates[storyText]', storyText)
    // for (const item of documentsToUpload) {
    //   data.append('files[]', item.file)
    // }
    // for (const item of websitesToUpload) {
    //   data.append('scrapeUrls', item.url)
    // }

    console.log(documentContext.docContext)

    data = await addContextToFormData(data, documentContext.docContext)

    // Only for updating
    for (const document_id of documentsToDelete) {
      data.append('documents_to_delete', document_id)
    }
    for (const document_id of websitesToDelete) {
      data.append('documents_to_delete', document_id)
    }

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }

    try {
      setLoading({ name: 'template', status: true })
      const res = await axios.post(
        `api/your-story/${selectedStory.id ? 'update' : 'save'}-template`,
        data,
        config
      )

      successToast(t(`eleo-success-${res.data.message}`, res.data.message))
      handleSelectTemplate({ value: res.data.id })
      setModalIsVisible({ content: '', visible: false })
      setDocumentsToUpload([])
      reload()
      setLatestTemplateId(res.data.id)
    } catch (error) {
      context.handleError(error)
    } finally {
      setLoading({ name: 'template', status: false })
      setTemplateName('')
    }
  }

  const handleDeleteTemplate = async (templateId) => {
    setTemplateIdtoDelete(templateId)
    setModalIsVisible({ content: 'DELETE_STORY_TEMPLATE', visible: true })
    documentContext.clearContext()
  }

  useEffect(() => {
    if (selectedStory) {
      setTemplateName(selectedStory.templateName)
    }
  }, [selectedStory])

  useEffect(() => {
    if (latestTemplateId && !templateListLoading) {
      handleSelectTemplate({ value: latestTemplateId })
      setLatestTemplateId('')
    }
  }, [latestTemplateId, templateListLoading])

  useEffect(() => {
    if (templatesRecordId && !latestTemplateId) {
      const template = templateList.find((template) => template.id === selectedStory.id)

      if (!template) {
        dispatch(setClearFields())
      }
    }
  }, [templatesRecordId, templateList.length, latestTemplateId])

  return (
    <>
      <div className='flex h-full flex-col justify-between overflow-x-clip bg-white'>
        <div className='flex flex-col gap-[30px] py-[20px]'>
          <div className='flex flex-wrap-reverse items-center justify-between px-[24px] lg:flex-nowrap'>
            <div
              id='selector'
              className='w-full items-center space-y-[6px] min-[1200px]:w-1/4'
              data-tooltip-id='yourStory-yourStory'
            >
              <p className='text-brand-body min-w-[75px] max-w-[75px] flex-shrink-0 flex-grow-0 text-[13px] font-medium capitalize leading-[1.2]'>
                <T keyName='eleo-select-your-story-text'>Your Story</T>
              </p>
              <ReactSelect
                options={templateOptions}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                // defaultValue={options[0]}
                setSelectedValue={handleSelectTemplate}
                value={transformSelectOptionValue(templateOptions, selectedStory.id, null)}
                showDeleteIcon
                deleteAction={(templateId) => handleDeleteTemplate(templateId)}
                name='MY-STORY'
              />
            </div>
            <div className='mb-[20px] flex w-full justify-end  lg:mb-0 lg:w-auto'>
              {selectedStory.id && (
                <Button
                  color='green'
                  className='h-[45px] w-full'
                  onClick={() => {
                    documentContext.clearContext()
                    dispatch(setClearFields())
                  }}
                  data-tooltip-id='yourStory-yourStoryClear'
                >
                  <p className='text-[15px] font-medium uppercase leading-none'>
                    <T keyName='eleo-account-add-new-story'>Add New Story</T>
                  </p>
                </Button>
              )}
            </div>
          </div>
          {/* <StoryDocuments
            documents={selectedStory?.documents.filter((doc) => doc.mimetype !== 'url')}
            websites={selectedStory?.documents.filter((doc) => doc.mimetype === 'url')}
            documentsToDelete={documentsToDelete}
            setDocumentsToDelete={setDocumentsToDelete}
            documentsToUpload={documentsToUpload}
            setDocumentsToUpload={setDocumentsToUpload}
            websitesToUpload={websitesToUpload}
            setWebsitesToUpload={setWebsitesToUpload}
            websitesToDelete={websitesToDelete}
            setWebsitesToDelete={setWebsitesToDelete}
          /> */}
          <StoryDocs
            documentContext={documentContext}
            setDocumentsToDelete={setDocumentsToDelete}
          />
          <div className='px-6'>
            <StoryGenerator
              storyText={storyText}
              setWordLength={setWordLength}
              wordLength={wordLength}
            />
            <div className='mt-1 rounded-[8px] bg-[#FFDA39] p-3 text-center text-[14px]'>
              <T keyName='eleo-account-story-warning'>
                The word count will include the number of words used in the story each time you will
                use it for generating content.
              </T>
            </div>
          </div>

          <>
            {modalIsVisible.visible && (
              <ActionModal
                modalKey={modalIsVisible.content}
                templateId={{ value: selectedStory.id }}
                templateName={templateName}
                setTemplateName={setTemplateName}
                handleTemplate={handleSaveStoryTemplate}
                loading={loading}
                setModalIsVisible={setModalIsVisible}
                reloadStoryTemplatesData={reload}
                deleteStoryEndpoint={() =>
                  YourStoryAPIService.deleteTemplate({
                    templateId: templateIdToDelete,
                  })
                }
              />
            )}
          </>
          <HelpTooltips tool='yourStory' />
          <Tour steps={steps} name='story' />
        </div>
        <div className='border-brand-form-accent sticky bottom-0 block justify-center border-t bg-white p-4'>
          <StoryActions
            handleShowSaveTemplateModal={showSaveTemplateModal}
            documentContext={documentContext}
          />
        </div>
      </div>
    </>
  )
}

export default YourStory
